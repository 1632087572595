<template>
  <div class="person-wrapper" v-if="person">
    <div v-if="displayIndividualName" class="row">
      <div class="col">
        <dt class="name">Full Name <span v-html="isEditedArr(['first_name', 'last_name'])"></span></dt>
        <dd v-if="person.first_name">
          <SlotCopy :valueToCopy="fullName">
            {{ fullName }}
          </SlotCopy>
        </dd>
      </div>
    </div>
    <div v-if="displayIndividualName" class="row">
      <div class="col">
        <dt class="name" v-html="setHeading('First', 'first_name')"></dt>
        <dd v-if="person.first_name">{{ person.first_name }}</dd>
      </div>
      <div class="col">
        <dt class="name" v-html="setHeading('Middle', 'middle_name')"></dt>
        <dd v-if="person.middle_name">{{ person.middle_name }}</dd>
      </div>
      <div class="col">
        <dt class="name" v-html="setHeading('Last', 'last_name')"></dt>
        <dd v-if="person.last_name">{{ person.last_name }}</dd>
      </div>
      <div class="col">
        <dt class="name" v-html="setHeading('Suffix', 'suffix')"></dt>
        <dd v-if="person.suffix">{{ person.suffix }}</dd>
      </div>
    </div>
    <div v-if="person.company_name" class="row">
      <div class="col">
        <dt class="name" v-html="setHeading('Company Name', 'company_name')"></dt>
        <dd>{{ person.company_name }}</dd>
      </div>
    </div>
    <div v-if="person.title" class="row">
      <div class="col">
        <dt class="name" v-html="setHeading('Titles', 'title')"></dt>
        <dd>{{ person.title }}</dd>
      </div>
    </div>
    <!--
      isManager conditional block here is introduced given a new contract for the /api/business_entity/contacts endpoint
      this will handle scenarios where `person.manager` is not present from the object but dynamically entered during the iteration in buisness-entity.vue
     -->
    <div v-if="['businessEntityView', 'managerView', 'memberView'].includes(view)">
      <div v-if="isManager"
        class=" row">
        <div class="col">
          <dt class="name">Designator</dt>
          <dd>{{ person.titles | memberRole }}</dd>
        </div>
      </div>
      <div v-if="!isManager && (person.manager || view === 'memberView' || view === 'businessEntityView')" class="row">
        <div class="col">
          <dt class="name" v-html="setHeading('Designator', 'designator')"></dt>
          <dd>{{ person.titles | memberRole }}</dd>
        </div>
      </div>
      <div v-if="!person.manager && view === 'managerView'" class="row">
        <div class="col">
          <dt class="name" v-html="setHeading('Designator', 'designator')"></dt>
          <dd>{{ person.titles | memberRole }}</dd>
        </div>
      </div>
    </div>
    <div v-if="person.phone" class="row">
      <div class="col">
        <dt class="name" v-html="setHeading('Phone', 'phone')"></dt>
        <dd>
          <SlotCopy :valueToCopy="person.phone">
            {{ person.phone }}
          </SlotCopy>
        </dd>
      </div>
    </div>
    <div v-if="isAnnualReport && jurisdictionCode === 'CA' && !isAddressOnly" class="row">
      <div class="col">
        <dt class="name">DoL Judgement?</dt>
        <dd>{{ person.outstanding_judgments ? 'Yes' : 'No' }}</dd>
      </div>
    </div>
    <div v-if="!isAnnualReport && person.state === 'CA' && !isAddressOnly" class="row">
      <div class="col">
        <dt class="name">DoL Judgement?</dt>
        <dd v-if="person.outstanding_judgments === undefined">N/A</dd>
        <dd v-else>{{ person.outstanding_judgments ? 'Yes' : 'No' }}</dd>
      </div>
    </div>
    <div v-if="person.email" class="row">
      <div class="col">
        <dt class="name" v-html="setHeading('Email', 'email')"></dt>
        <dd>
          <SlotCopy :valueToCopy="person.email">
            {{ person.email }}
          </SlotCopy>
        </dd>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <slot name="update-contact-button"></slot> <!-- Add this slot for the button -->
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt class="name" v-if="!isAddressOnly">Address <span v-html="isEditedArr(addressArr)"></span></dt>
        <dd v-if="addressType === 'virtual'">
          <SlotCopy :valueToCopy="fullAddress">
            <div>
              {{ address_1 }}
              <span v-if="address_2">
                {{ address_2 }}
              </span>
              <span v-if="person.mailbox_identifier">{{ virtualAddressIdentifier }}</span><br />
              <span v-if="person.state">{{ person.city }}, {{ person.state }}</span>
              <span v-else>{{ person.city }}</span>
              {{ person.zip }}<br />
              <div v-if="person.county || person.country">
                <span v-if="person.county">{{ person.county }}</span>
                <span v-if="person.country">{{ person.country }}</span>
                <br />
              </div>
            </div>
          </SlotCopy>
        </dd>
        <dd v-else>
          <SlotCopy :valueToCopy="fullAddress">
            <div>
              {{ address_1 }}<br />
              <span v-if="address_2">
                {{ address_2 }}<br />
              </span>
              <span v-if="person.state">{{ person.city }}, {{ person.state }}</span>
              <span v-else>{{ person.city }}</span>
              {{ person.zip }}<br />
              <div v-if="person.county || person.country">
                <span v-if="person.county">{{ person.county }} </span>
                <span v-if="person.country">{{ person.country }}</span>
                <br />
              </div>
            </div>
          </SlotCopy>
        </dd>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <slot v-if="$slots.default"></slot>
        <slot v-else name="update-address-button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { memberRoleMixin, computeFullNameOfPersonMixin } from '../mixins';
import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";

export default {
  /**
   * Component to display information about a person/contact (name, phone, email) as well as address.
   * This component can also be used to display an address without any contact information.
   */
  name: 'Person',

  components: {SlotCopy },

  mixins: [ memberRoleMixin, computeFullNameOfPersonMixin],

  props: ['person', 'addressType', 'jurisdictionCode', 'isAnnualReport', 'showIndividualName', 'isManager', 'view'],

  data () {
    return {
      addressArr: ['city', 'state', 'zip', 'country']
    }
  },

  computed: {
    fullAddress() {
      return `${this.address_1} ${this.address_2} ${this.person.city}, ${this.person.state} ${this.person.zip}`
    },
    fullName() {
      return this.computeFullNameOfPerson(this.person);
    },
    // The legacy Address serializer returns it as address_1 / address_2
    // The current Address serializer returns it as address_line1 / address_line2
    // ... so we're accommodating both
    address_1() {
      if ('address_line1' in this.person) { return this.person.address_line1}
      if ('address_1' in this.person) { return this.person.address_1}
      return ''
    },
    address_2() {
      if ('address_line2' in this.person) { return this.person.address_line2}
      if ('address_2' in this.person) { return this.person.address_2}
      return ''
    },
    isAddressOnly() {
      // Sometimes we use this component to display simple addresses with no person or contact information.
      // In this case, we don't need to show the 'Address' description term element.
      return !this.person.first_name
        && !this.person.last_name
        && !this.person.company_name
        && !this.person.phone
        && !this.person.email;
    },
    virtualAddressIdentifier() {
      const id = this.person.mailbox_identifier;
      return id.replace('PMB ', '#');
    },
   displayIndividualName() {
      return (
        this.showIndividualName ||
        (!this.person.company_name &&
          (this.person.first_name ||
            this.person.middle_name ||
            this.person.last_name))
      );
    },
  },

  methods: {
    isEditedArr(propArr) {
      const isEdited = this.person?.isEdited ? propArr.find(x => this.person.isEdited[x] === true) : false;
      return isEdited ? `<em>(edited)</em>` : '';
    },
    setHeading(title, property) {
      return this.person?.isEdited?.[property] === true ? `${title} (edited)` : `${title}`
    }
  }

};
</script>

<style lang="scss" scoped>
  dt.name {
    font-size: 9pt;
    color: #969696;
  }
  .person-wrapper {
    margin-bottom: 20px;
  }
</style>
