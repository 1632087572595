import { format } from 'date-fns';
export const COUPON_LABELS = {
  ISSUED: 'Issued ',
  REDEEMED: 'Redeemed',
  EXPIRED: 'Expired',
}

export const getCouponStatus = (couponJourney, relatedPromotion, termEndDate) => {

  const DEFAULT_STATUS = {
    buttonStatus: true,
    statusMessage: null,
  };

  if ((!couponJourney || Object.keys(couponJourney).length === 0) &&
      (!relatedPromotion || Object.keys(relatedPromotion).length === 0)) {
    return DEFAULT_STATUS;
  }

  const statusMapping = {
    not_started: {
      buttonStatus: false,
      statusMessage: couponJourney?.createdDatetime
        ? `${COUPON_LABELS.ISSUED} ${format(new Date(couponJourney?.createdDatetime), 'MMMM dd, yyyy hh:mm:ss a')}`
        : '', // Fallback if createdDatetime is missing
    },
    completed: relatedPromotion ? {
      buttonStatus: false,
      statusMessage: COUPON_LABELS.REDEEMED,
    } : DEFAULT_STATUS,
    expired: termEndDate && new Date() < new Date(termEndDate) ? {
      buttonStatus: true,
      statusMessage: COUPON_LABELS.EXPIRED,
    } : DEFAULT_STATUS,
  };

  return statusMapping[couponJourney?.status] || DEFAULT_STATUS;
}

